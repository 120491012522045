.voi_label {
  color: var(--sjs-primary-forecolor, #000);
  float: left;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  text-align: left;
  width: 100%;
  line-height: 1.72;
}

.voi_blurb {
  font-size: 12px;
  margin: 0;
  text-align: left;
}

.radio_group_voi {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.voi_option {
  box-shadow: 0 3px 5px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.24);
  align-items: center;
  background: #fff;
  border: 1px solid #777;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px;
  padding: 4px;
  text-align: center;
  height: 140px;
  width: 140px;

  transition-property: border, background-color, box-shadow;
  transition-duration: .2s;
  transition-timing-function: linear;

  label {
    font-size: 1em;
    margin: 4px 0;
    height: auto;
    width: 100%;
    line-height: 1;
    white-space: normal;
    cursor: pointer;
  
    input {
      opacity: 0;
      position: absolute;
      clip: rect(0, 0, 0, 0);
      width: 1px;
      height: 1px;
    }
  }
  
  &:focus-within {
    outline: none;
    border-color: var(--primary, #1700f4);
    box-shadow: 0 0 5px var(--primary, #1700f4);
  }

  @media(max-device-width: 812px) {
    height: 100px;
    width: 100px;
    padding: 0;
    
    label {
      margin: 2px;
      font-size: .71em;
    }  
  }

  .vehicle_img {
    height: auto;
    width: 120px;
    align-self: flex-end;
  
    @media(max-device-width: 812px) {
      width: 100px;
    }
  }
}

.voi_option_active {
  border: 2px solid var(--primary, #00095b);
  background-color: #52B2D733;
  box-shadow: none;
}

.tabs_container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 8px;
}

.tab_button {
  padding: 8px 16px;
  border: 1px solid var(--sjs-primary-backcolor, #1700f4);
  background: transparent;
  border-radius: var(--sjs-corner-radius, 4px);
  cursor: pointer;
  font-size: 14px;
  color: var(--sjs-primary-backcolor, #1700f4);
  transition: all 0.2s ease;

  &:hover {
    background: var(--sjs-primary-backcolor-light, #1700f433);
  }
}

.tab_active {
  background: var(--sjs-primary-backcolor, #1700f4);
  color: white;

  &:hover {
    background: var(--sjs-primary-backcolor, #1700f4);
  }
}
