.radio_group_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: center;
  width: 100%;
}

.radio_box_small {
  margin: 5px;
  box-shadow: 0 3px 5px rgba(0,0,0,0.12),0 2px 4px rgba(0,0,0,0.24);
  align-items: center;
  background: #fff;
  border: 1px solid #777;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 60px;
  justify-content: center;
  margin: 10px;
  padding: 4px;
  text-align: center;
  width: 60px;

  transition-property: border, background, box-shadow;
  transition-duration: .2s;
  transition-timing-function: linear;

  label {
    font-size: .8em;
    margin: 5px 0;
    height: auto;
    width: 100%;
    line-height: 1;
    white-space: normal;
    cursor: pointer;

    input {
      opacity: 0;
      position: absolute;
      clip: rect(0, 0, 0, 0);
      width: 1px;
      height: 1px;
    }
  }
  
  &:focus-within {
    outline:none;
    border-color: var(--primary, #1700f4);
    box-shadow:0 0 5px var(--primary, #1700f4);
  }

  @media(min-width: 768px) {
    flex-direction: row;
    height: 60px;
    width: 60px;
  }

  @media(max-device-width: 812px) {
    width: 40%;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  span {
    width: 90% !important;
  }
}

.radio_box {
  box-shadow: 0 3px 5px rgba(0,0,0,0.12),0 2px 4px rgba(0,0,0,0.24);
  align-items: center;
  background: #fff;
  border: 1px solid #777;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 80px;
  justify-content: center;
  margin: 10px;
  padding: 4px;
  text-align: center;
  width: 100px;

  transition-property: border, background, box-shadow;
  transition-duration: .2s;
  transition-timing-function: linear;

  @media(max-device-width: 812px) {
    width: 40%;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  label {
    font-size: .8em;
    margin: 5px 0;
    height: auto;
    width: 100%;
    line-height: 1;
    white-space: normal;
    cursor: pointer;

    input {
      opacity: 0;
      position: absolute;
      clip: rect(0, 0, 0, 0);
      width: 1px;
      height: 1px;
    }
  }
  
  &:focus-within {
    outline:none;
    border-color: var(--primary, #1700f4);
    box-shadow:0 0 5px var(--primary, #1700f4);
  }

  span {
    width: 90% !important;
  }
}

.radio_box_large {
  box-shadow: 0 3px 5px rgba(0,0,0,0.12),0 2px 4px rgba(0,0,0,0.24);
  align-items: center;
  background: #fff;
  border: 1px solid #777;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 150px;
  justify-content: center;
  margin: 10px;
  padding: 4px;
  text-align: center;
  width: 150px;

  transition-property: border, background, box-shadow;
  transition-duration: .2s;
  transition-timing-function: linear;

  label {
    font-size: .8em;
    margin: 5px 0;
    height: auto;
    width: 100%;
    line-height: 1;
    white-space: normal;
    cursor: pointer;

    input {
      opacity: 0;
      position: absolute;
      clip: rect(0, 0, 0, 0);
      width: 1px;
      height: 1px;
    }
  }
  
  &:focus-within {
    outline:none;
    border-color: var(--primary, #1700f4);
    box-shadow:0 0 5px var(--primary, #1700f4);
  }

  @media(min-width: 768px) {
    flex-direction: row;
    height: 140px;
    width: 18%;
    margin-top: 1%;
    margin-left: auto;
    margin-right: auto;
  }

  @media(max-device-width: 812px) {
    flex-direction: row;
    height: 60px;
    width: 100%;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  span {
    width: 90% !important;
  }
}

.radio_box_active {
  border: 2px solid var(--primary, #00095b);
  background: #52B2D733;
  box-shadow: none;
}
