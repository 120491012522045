.booking_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.booking_button_container {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
}

.booking_container_buttons {
  margin: 4px;
  min-height: 40px;
  min-width: 100px;
}