a,
a:link,
a:visited {
    text-decoration: underline;
}

.headline-1-large-semibold.onDark {
    color: theme('colors.text-ondark-strong');
}

.fds-button-primary {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: theme('spacing.sm') theme('spacing.md');
    border-radius: theme('borderRadius.full');
    text-align: center;
    border: none;
    cursor: pointer;
    min-height: 40px;
    background-color: theme('colors.component-button-primary-onlight-default');
    color: theme('colors.component-button-primary-onlight-content');
    transition: all 0.2s ease-in-out;
}

.fds-button-primary:hover {
    background-color: theme('colors.component-button-primary-onlight-hover');
}

.fds-button-primary:focus {
    outline: none;
    box-shadow: 0 0 0 2px theme('colors.component-button-primary-focus-default');
}

.fds-button-primary:disabled {
    background-color: theme('colors.component-button-primary-ondark-disabled');
    color: theme('colors.component-button-primary-ondark-disabled');
    cursor: not-allowed;
}

.fds-question-label {
    color: theme('colors.text-onlight-moderate-default');
    padding-top: 0;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
}

.fds-question-description {
    color: theme('colors.text-onlight-subtle');
    padding: theme('spacing.xs') 0;
}

.fds-input {
    width: 100%;
    padding: theme('spacing.sm') theme('spacing.lg');
    border-radius: theme('borderRadius.md');
    border: theme('borderWidth.xs') solid theme('colors.stroke-onlight-moderate-default');
    background-color: theme('colors.fill-onlight-highcontrast-default');    
    color: theme('colors.text-onlight-strong');
    outline: theme('borderWidth.xs') solid transparent;
}

.fds-input:focus {
    outline: theme('borderWidth.xs') solid theme('colors.stroke-onlight-strongest-focus');
}

.fds-input--disabled {
    background-color: theme('colors.fill-onlight-disabled');
    border-color: theme('colors.stroke-onlight-disabled');
}

.fds-input--readonly {
    background-color: theme('colors.fill-onlight-subtle');
}

.fds-input--error {
    border: 2px solid theme('colors.stroke-onlight-danger');
}

/* Radio Button Group custom styles */
.fds-radio-button-group-container {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    gap: theme('spacing.md');
    width: 100%;
}

/* Base styles for all radio button items */
.fds-radio-button-group-item,
.fds-radio-button-group-item-small,
.fds-radio-button-group-item-large {
    display: flex;
    align-items: center;
    justify-content: center;
    background: theme('colors.fill-onlight-highcontrast-default');
    border: theme('borderWidth.xs') solid theme('colors.stroke-onlight-moderate-default');
    border-radius: theme('borderRadius.md');
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    padding: 0;
}

/* Size variants */
.fds-radio-button-group-item {
    width: 100px;
    height: 80px;
}

.fds-radio-button-group-item-small {
    width: 60px;
    height: 60px;
}

.fds-radio-button-group-item-large {
    width: 150px;
    height: 100px;
}

/* Label styles for all sizes */
.fds-radio-button-group-item label,
.fds-radio-button-group-item-small label,
.fds-radio-button-group-item-large label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: theme('spacing.sm');
    margin: 0;
    text-align: center;
    color: inherit;
    font-size: theme('fontSize.sm');
    cursor: pointer;
    word-break: break-word;
    white-space: normal;
    line-height: 1.2;
}

/* Add hover styles for all variants */
.fds-radio-button-group-item:hover,
.fds-radio-button-group-item-small:hover,
.fds-radio-button-group-item-large:hover {
    border-color: theme('colors.stroke-onlight-strong');
    background: theme('colors.fill-onlight-interactive-hover');
    color: theme('colors.text-ondark-strong');
}

/* Add focus styles for all variants */
.fds-radio-button-group-item:focus-within,
.fds-radio-button-group-item-small:focus-within,
.fds-radio-button-group-item-large:focus-within {
    outline: none;
    border-color: theme('colors.component-button-primary-onlight-default');
    box-shadow: 0 0 0 2px theme('colors.component-button-primary-focus-default');
}

.fds-radio-button-group-item input[type="radio"],
.fds-radio-button-group-item input[type="checkbox"],
.fds-radio-button-group-item-small input[type="radio"],
.fds-radio-button-group-item-small input[type="checkbox"],
.fds-radio-button-group-item-large input[type="radio"],
.fds-radio-button-group-item-large input[type="checkbox"] {
    position: absolute !important;
    opacity: 0 !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
    pointer-events: none !important;
}

.fds-radio-button-group-item-active {
    background: theme('colors.fill-onlight-interactive');
    color: theme('colors.text-ondark-strong');
    border: theme('borderWidth.xs') solid theme('colors.component-button-primary-onlight-default');
}

.fds-radio-button-group-item-disabled {
    background-color: theme('colors.fill-onlight-disabled');
    border-color: theme('colors.stroke-onlight-disabled');
    color: theme('colors.stroke-onlight-disabled');
    cursor: not-allowed;
}

.fds-radio-button-group-item-disabled label {
    cursor: not-allowed;
}

/* Handle the question number and text container */
.sd-question__header {
    display: flex;
    flex-wrap: wrap;
    gap: theme('spacing.xs');
    align-items: flex-start;
}

.sd-element__num {
    flex-shrink: 0;
}

.sv-string-viewer {
    flex: 1;
    min-width: 0;
}

.fds-logo-only-header {
    display: flex;
    width: 100%;
    height: 3.5rem;
    padding: theme('spacing.sm');
    justify-content: center;
    align-items: center;
    gap: 6.5rem;
    flex-shrink: 0;    
    background: theme('colors.fill-onlight-highcontrast-default');
}

.fds-header-logo {
    height: 31px;
}

/* CheckboxVOI custom styles */
.fds-checkbox-voi-container {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.fds-checkbox-voi-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: theme('spacing.sm');
    padding: theme('spacing.xs');
    width: 140px;
    height: 140px;
    background: theme('colors.fill-onlight-highcontrast-default');
    border: theme('borderWidth.xs') solid theme('colors.stroke-onlight-moderate-default');
    border-radius: theme('borderRadius.md');
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: theme('boxShadow.sm');
}

.fds-checkbox-voi-item:hover {
    border-color: theme('colors.stroke-onlight-strong');
    background: theme('colors.fill-onlight-interactive-hover');
    color: theme('colors.text-ondark-strong');
}

.fds-checkbox-voi-item:focus-within {
    outline: none;
    border-color: theme('colors.component-button-primary-onlight-default');
    box-shadow: 0 0 0 2px theme('colors.component-button-primary-focus-default');
}

.fds-checkbox-voi-item label {
    width: 100%;
    margin: theme('spacing.xs') 0;
    text-align: center;
    font-size: theme('fontSize.sm');
    line-height: 1;
    white-space: normal;
    cursor: pointer;
}

.fds-checkbox-voi-item input[type="checkbox"] {
    position: absolute !important;
    opacity: 0 !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
    pointer-events: none !important;
}

.fds-checkbox-voi-item-active {
    background: theme('colors.fill-onlight-interactive');
    border: theme('borderWidth.xs') solid theme('colors.component-button-primary-onlight-default');
    box-shadow: none;
    color: theme('colors.text-ondark-strong');
}

.fds-checkbox-voi-item-disabled {
    background-color: theme('colors.fill-onlight-disabled');
    border-color: theme('colors.stroke-onlight-disabled');
    color: theme('colors.stroke-onlight-disabled');
    cursor: not-allowed;
}

.fds-checkbox-voi-item-disabled label {
    cursor: not-allowed;
}

.fds-checkbox-voi-item-image {
    height: auto;
    width: 120px;
    align-self: flex-end;
}

.fds-title-spacing {
    margin-top: theme('spacing.md');
    padding-bottom: theme('spacing.sm');
}

/* Error styling */
.fds-error {
    padding: theme('spacing.sm') calc(var(--sd-base-padding) + var(--sv-element-add-padding-left, 0px)) theme('spacing.sm') calc(var(--sd-base-padding) + var(--sv-element-add-padding-left, 0px));
}

.sd-element--nested .fds-error {
    padding: 0;
}

.fds-error > div {
    display: flex;
    align-items: center;
}

.fds-error-icon {
    background-image: url('../../../assets/icons/ford/error.svg');
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    margin-right: theme('spacing.xs');
}

.fds-error-item {
    color: theme('colors.text-onlight-danger');
    font-size: theme('fontSize.base');
}

.fds-hide {
    height: 0;
    margin: 0;
    padding: 0;
    display: none;
}

.fds-body__page {
    margin-top: 0px;
}

@media (min-width: 640px) {
    .fds-body__page {
        margin-top: -20px;
    }
}