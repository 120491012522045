.footer-container {
    padding: 16px;
    background-color: var(--semantic-color-fill-onlight-subtle);
    border-top: 1px solid var(--semantic-color-stroke-onlight-subtle-dividers);
    width: 100%;
}

@media (min-width: 768px) {
    .footer-container {
        padding: 24px 48px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.language-change {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 358px;
    height: 36px;
}

.globe-icon {
    width: 38px;
    height: 36px;
}

.language-select {
    margin-left: 16px;
    color: var(--semantic-color-text-onlight-moderate-default);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Ford F-1', Arial, sans-serif;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 4px 8px;
    width: calc(100% - 54px);
}

.language-select:focus {
    outline: 2px solid var(--semantic-color-interactive-onlight-focus);
    border-radius: 4px;
}

.links-section {
    margin-top: 44px;
    width: 100%;
    max-width: 358px;
}

@media (min-width: 768px) {
    .links-section {
        margin-top: 0;
        max-width: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
        flex: 1;
        justify-content: center;
        margin-left: 48px;
        margin-right: 48px;
    }
}

.footer-link {
    color: var(--semantic-color-text-onlight-moderate-default);
    font-size: 11px;
    line-height: 16px;
    font-family: 'Ford F-1', Arial, sans-serif;
    margin-bottom: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-decoration: none !important;
}

@media (min-width: 768px) {
    .footer-link {
        margin-bottom: 0;
    }
}

.footer-link:hover,
.footer-link:focus,
.footer-link:active,
.footer-link:visited {
    color: var(--semantic-color-text-onlight-moderate-default);
    text-decoration: none !important;
}

.privacy-icon {
    width: 29px;
    height: 16px;
    margin-left: 8px;
    vertical-align: middle;
}

.ford-logo {
    margin-top: 44px;
    width: 83.37px;
    height: 31px;
}

@media (min-width: 768px) {
    .ford-logo {
        margin-top: 0;
    }
}

.ford-signature {
    width: 80px;
    height: 31px;
}
  