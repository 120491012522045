
// Gray Scale
$black: #0c0c0c;
$white: #fff;
$gray: #9B9B9B;
$gray_dark: #4A4A4A;
$gray_light: #F5F4F4;

// Other Colors
$blue: #47708E;
$blue_dark: #00142e;
$blue_ford: #00095b;
$green: #5BA20A;
//$red: #A31E2F;
$red: #D92E14;
$orange: #C17034;

/* Ranger Colors */
$ranger_yellow: #ffd400;
$ranger_black: #0C1218;
$ranger_gray: #777779;

// Tab Focus Blue
$focus_blue: #1700f4;
$ford_skyblue: #066FEF;

