.segmented-control-container {
  display: flex;
  align-items: center;
  padding: theme('spacing.xs');
  background-color: theme('colors.fill-onlight-highcontrast-default');
  border: 1px solid theme('colors.stroke-onlight-subtle-dividers');
  border-radius: theme('borderRadius.md');
  width: fit-content;
  gap: -1px;
}

.segment-wrapper {
  display: flex;
  flex-direction: row;
  padding: 0;
  position: relative;
}

.segment-button {
  display: flex;
  padding: theme('spacing.xs');
  justify-content: center;
  align-items: center;
  gap: var(--space-None, 0px);
  flex: 1 0 0;
  position: relative;
  z-index: 1;

  border-radius: theme('borderRadius.sm');
  cursor: pointer;
  transition: background-color 0.2s;
}

.segment-button.active {
  background-color: theme('colors.component-button-primary-onlight-default');
  color: theme('colors.text-ondark-strong');
}

.segment-button.inactive {
  background-color: transparent;
  color: theme('colors.text-onlight-strong');
}

.segment-button.inactive:hover {
  background-color: theme('colors.fill-onlight-interactive-hover');
  color: theme('colors.text-ondark-strong');
}

/* Only show divider when both segments are inactive */
.segment-wrapper:not(:last-child) .segment-button.inactive + .segment-divider {
  position: absolute;
  right: 0;
  top: 7px;
  width: 1px;
  height: 16px;
  background-color: theme('colors.stroke-onlight-subtle-dividers');
}

.segment-divider {
  position: absolute;
  right: 0;
  top: 7px;
  width: 1px;
  height: 16px;
  z-index: 0;
  background-color: theme('colors.stroke-onlight-subtle-dividers');
} 